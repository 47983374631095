import React from "react";

export default function Maintenance(){

    return (
        
        <div className="w-[70%] m-auto font-[Montserrat] text-center p-20 max-md:w-full max-md:p-[20px] max-md:py-20">
            <h1 className="text-5xl font-semibold max-md:text-4xl ">    
                We're currently under maintenance.
            </h1>
            <h3 className="text-xl leading-loose max-md:text-lg">It's going to take some time to fix the error. We'll be back Soon.</h3>
            <p></p>
        </div>
    )
}